import { connect } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../state/store';
import InputTitle from '../terminal/InputTitle';
import { CardContent } from './Cards';

const CardContainer = styled.div<StateProps>`
  width: 47%;
  margin-bottom: 35px;
  align-content: center;
  position: relative;
  :hover > .clickable {
    box-shadow: 8px 8px 6px ${(props) => props.darkColor};
    cursor: pointer;
  }
  :active > .clickable {
    box-shadow: 2px 2px 4px ${(props) => props.darkColor};
  }
`;

const CardImage = styled.div<StateProps>`
  aspect-ratio: 1 / 1;
  border-radius: 15px;
  background-color: white;
  background-size: cover;
  background-position: center;
  transition: 0.1s;
  box-shadow: 6px 6px 4px ${(props) => props.darkColor};
`;

const CardTitle = styled.div<StateProps>`
  margin-bottom: 5px;
  width: fit-content;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  border-radius: 15px;
  text-align: center;
  box-shadow: 6px 6px 4px ${(props) => props.darkColor};
  position: absolute;
  bottom: -15px;
  color: ${(props) => props.darkColor};
  background-color: ${(props) => props.lightColor};
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  h3 {
    margin-top: 0;
    margin-bottom: 0px;
  }
`;

const TerminalTitle = styled(InputTitle)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

type CardProps = StateProps & CardContent;
function Card(props: CardProps): JSX.Element {
  function onClick(url: string) {
    if (url !== '') {
      window.open(url, '_blank');
    }
  }
  if (!props.isSpacer)
    return (
      <CardContainer
        key={props.title}
        onClick={() => onClick(props.onClickUrl ?? '')}
        {...props}
      >
        <CardTitle className='clickable' {...props}>
          <h3>{props.title}</h3>
        </CardTitle>
        <CardImage
          className='clickable'
          style={{ backgroundImage: props.imageUrl }}
          {...props}
        />
      </CardContainer>
    );

  const precursor = `${props.precursor}@adin.dev ~ % `;
  return <TerminalTitle precursor={precursor} input={props.title} />;
}

const mapState = (state: RootState) => ({
  ...state.theme.themeSettings,
});
type StateProps = ReturnType<typeof mapState>;

connect<StateProps, {}, {}, RootState>(mapState, {})(Card);
export default connect<StateProps, {}, {}, RootState>(mapState, {})(Card);
