import { connect } from 'react-redux';
import styled from 'styled-components';
import { WIDTH } from '../common/sizes';
import { RootState } from '../state/store';
import Card from './Card';

const Container = styled.div<StateProps>`
  width: ${WIDTH};
  min-width: 340px;
  max-width: 600px;
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export type CardContent = {
  isSpacer?: boolean;
  precursor?: string;
  title: string;
  onClickUrl?: string;
  imageUrl?: string;
};
const cardContents: CardContent[] = [
  { isSpacer: true, precursor: 'adin', title: 'work-history' },
  {
    title: 'Meta',
    onClickUrl: 'https://adin.dev/adin-kwok-resume.pdf',
    imageUrl: 'url("/images/meta.jpg")',
  },
  {
    title: 'Amazon (AWS)',
    onClickUrl: 'https://adin.dev/adin-kwok-resume.pdf',
    imageUrl: 'url("/images/aws.jpg")',
  },
  {
    title: 'Intersect (Acquired)',
    onClickUrl: '',
    imageUrl: 'url("/images/corelogic.png")',
  },
  { isSpacer: true, precursor: 'freebonnyb', title: 'music' },
  {
    title: 'Free Bonny B: E1',
    onClickUrl:
      'https://distrokid.com/hyperfollow/bonnyb/free-bonny-b-episode-i',
    imageUrl: 'url("/images/fbbe1.jpg")',
  },
  {
    title: 'What the Beans',
    onClickUrl: 'https://distrokid.com/hyperfollow/bonnyb/what-the-beans',
    imageUrl: 'url("/images/wtb.jpg")',
  },
  {
    title: 'MITB',
    onClickUrl:
      'https://distrokid.com/hyperfollow/bonnyb/microphone-in-the-basement',
    imageUrl: 'url("/images/mitb.jpg")',
  },
  {
    title: 'Heavy in Time',
    onClickUrl: 'https://distrokid.com/hyperfollow/bonnyb/heavy-in-time',
    imageUrl: 'url("/images/heavyintime.webp")',
  },
];

function Cards(props: StateProps) {
  return (
    <Container {...props}>
      {cardContents.map((content) => (
        <Card {...content} {...props} />
      ))}
    </Container>
  );
}

const mapState = (state: RootState) => ({
  ...state.theme.themeSettings,
});
type StateProps = ReturnType<typeof mapState>;

export default connect<StateProps, {}, {}, RootState>(mapState, {})(Cards);
