import { connect } from 'react-redux';
import styled from 'styled-components';
import { WIDTH } from '../common/sizes';
import { RootState } from '../state/store';
import InputTitle from './InputTitle';

const Container = styled.div<StateProps>`
  width: ${WIDTH};
  min-width: 340px;
  max-width: 600px;
  margin-top: 40px;
  margin-bottom: 50px;
`;

const Input = styled.div<StateProps>`
  width: 100%;
  box-shadow: 6px 6px 4px ${(props) => props.darkColor};
  background-color: black;
  font-family: 'Roboto Mono', monospace;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  font-size: 16px;
`;

const Output = styled(Input)`
  margin-top: 25px;
  color: #c8e6c9;
  white-space: break-spaces;
`;

const Env = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

function Terminal(props: StateProps) {
  return (
    <Container {...props}>
      <InputTitle precursor='adin@adin.dev ~ % ' input='status' />
      <Output {...props}>
        <Env>
          Incoming Software Engineer at Meta.
          {'\n.'} UBC Business + Computer Science (BUCS).
          {'\n.'} Currently NOT looking for work.
          {'\n.\n'}
          Enjoying my time with friends and family rn.
          {'\n.'} Learning photography.
          {'\n.'} Have a real cute schnoodle dog.
          {'\n.\n'}
          Still making music.
          {'\n.'} RIP Bonny B.
          {'\n.'} Not stopping.
          {'\n.'}
        </Env>
      </Output>
    </Container>
  );
}

const mapState = (state: RootState) => ({
  ...state.theme.themeSettings,
});
type StateProps = ReturnType<typeof mapState>;

export default connect<StateProps, {}, {}, RootState>(mapState, {})(Terminal);
