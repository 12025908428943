export interface ThemeState {
  darkColor: string;
  lightColor: string;
  textColor: string;
  silver: string;
  backgroundGradient: {
    color0: string;
    color1: string;
    color2: string;
    color3: string;
  };
  headerGradient: {
    color0: string;
    color1: string;
    color2: string;
    color3: string;
  };
}

type Theme = {
  darkColor: string;
  lightColor: string;
  textColor: string;
  silver: string;
  backgroundGradient: {
    color0: string;
    color1: string;
    color2: string;
    color3: string;
  };
  headerGradient: {
    color0: string;
    color1: string;
    color2: string;
    color3: string;
  };
};

const themes: Record<number, Theme> = {
  0: {
    darkColor: '#000000',
    lightColor: '#F5F5F5',
    textColor: '#F5F5F5',
    silver: '#BDC3C7',
    backgroundGradient: {
      color0: '#9575CD',
      color1: '#616161',
      color2: '#607D8B',
      color3: '#FF5722',
    },
    headerGradient: {
      color0: '#757575',
      color1: '#546E7A',
      color2: '#616161',
      color3: '#455A64',
    },
  },
};

// Initial state
const INITIAL_STATE: ThemeState = {
  ...themes[0],
};

// Types
export const SET_THEME = 'SET_SHADOW_COLOR';

// Actions
export const setShadowColor = (theme: number) => {
  return {
    type: SET_THEME,
    theme: themes[theme],
  };
};

// Reducer
function reducer(state: ThemeState = INITIAL_STATE, action: any): ThemeState {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        ...action.theme,
      };
    default:
      return state;
  }
}

export default reducer;
