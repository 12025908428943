import { connect } from 'react-redux';
import styled from 'styled-components';
import { WIDTH } from '../common/sizes';
import { RootState } from '../state/store';

const drumFiles: string[] = ['kick', 'snare', 'perc', 'hat'];

const DrumDiv = styled.div<StateProps>`
  width: ${WIDTH};
  min-width: 340px;
  max-width: 600px;
  justify-content: space-around;
  margin-bottom: 25px;
  display: flex;
  box-shadow: 6px 6px 4px ${(props) => props.darkColor};
`;

const PadDiv = styled.div<StateProps>`
  width: 25%;
  transition: 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1px;
  background-color: ${(props) => props.lightColor};
  box-shadow: 0px -6px 4px ${(props) => props.darkColor};
  &:hover {
    box-shadow: 0px -8px 4px ${(props) => props.darkColor};
    background-color: ${(props) => props.silver};
    cursor: pointer;
  }
  &:active {
    box-shadow: 0px -4px 4px ${(props) => props.darkColor};
  }
`;

const PadTitle = styled.h1<StateProps>`
  font-size: 15px;
  text-shadow: 1px 1px 2px ${(props) => props.darkColor};
  color: ${(props) => props.darkColor};
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
`;

// snare hat kick perc
function Drum(props: StateProps) {
  const drumFilesAudios: HTMLAudioElement[] = drumFiles.map((f) => {
    const audio = new Audio(`/sound_fx/drum/${f}.wav`);
    audio.load();
    return audio;
  });

  const play = (i: number) => {
    drumFilesAudios[i].load();
    drumFilesAudios[i].play();
  };

  return (
    <DrumDiv {...props}>
      <PadDiv onClick={() => play(0)} {...props}>
        <PadTitle {...props}>Kick</PadTitle>
      </PadDiv>
      <PadDiv {...props} onClick={() => play(1)}>
        <PadTitle {...props}>Snare</PadTitle>
      </PadDiv>
      <PadDiv {...props} onClick={() => play(2)}>
        <PadTitle {...props}>Perc</PadTitle>
      </PadDiv>
      <PadDiv {...props} onClick={() => play(3)}>
        <PadTitle {...props}>Hat</PadTitle>
      </PadDiv>
    </DrumDiv>
  );
}

const mapState = (state: RootState) => ({
  ...state.theme.themeSettings,
});
type StateProps = ReturnType<typeof mapState>;

export default connect<StateProps, {}, {}, RootState>(mapState, {})(Drum);
