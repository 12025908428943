import { connect } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import Cards from '../cards/Cards';
import Header from '../header/Header';
import Drum from '../midi/drum';
import Piano from '../midi/piano';
import { RootState } from '../state/store';
import Terminal from '../terminal/Terminal';

const HomeDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

const PLACEHOLDER = styled.div`
  height: 25px;
`;

const GlobalStyle = createGlobalStyle<StateProps>`
  body {
    background: linear-gradient(
      -45deg,
      ${(props) => props.backgroundGradient.color0},
      ${(props) => props.backgroundGradient.color1},
      ${(props) => props.backgroundGradient.color2},
      ${(props) => props.backgroundGradient.color3}
    );
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    height: 100vh;
    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }
`;

function Home(props: StateProps) {
  return (
    <div>
      <GlobalStyle {...props} />
      <Header />
      <HomeDiv>
        <Terminal />
        <Drum />
        <Piano />
        <Cards />
        {/* <AboutMe />
        <Music />
        <Coding /> */}
        <PLACEHOLDER />
      </HomeDiv>
    </div>
  );
}

const mapState = (state: RootState) => ({
  ...state.theme.themeSettings,
});
type StateProps = ReturnType<typeof mapState>;

export default connect<StateProps, {}, {}, RootState>(mapState, {})(Home);
