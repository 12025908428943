import { connect } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../state/store';

const Container = styled.div<StateProps>`
  letter-spacing: 1px;
  top: 0;
  position: sticky;
  width: 100%;
  box-shadow: 0px 6px 4px ${(props) => props.darkColor};
  padding-left: min(40px, 5%);
  padding-right: min(40px, 5%);
  display: flex;
  align-items: center;
  min-height: 60px;
  justify-content: space-around;
  z-index: 1;
  box-sizing: border-box;
  letter-spacing: 1px;

  background: linear-gradient(
    -45deg,
    ${(props) => props.headerGradient.color0},
    ${(props) => props.headerGradient.color1},
    ${(props) => props.headerGradient.color2},
    ${(props) => props.headerGradient.color3}
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const TitleDiv = styled.div<StateProps>`
  display: flex;
  align-items: center;

  h1 {
    margin-top: 0;
    margin-bottom: 0;
    text-shadow: 2px 2px 2px ${(props) => props.darkColor};
    font-size: 25px;
    color: ${(props) => props.textColor};
    white-space: nowrap;
  }
`;

const LinksDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: min(20px, 5%);
  padding-top: 10px;
  padding-bottom: 10px;
`;

const LinkText = styled.h3<StateProps>`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 10px;
  text-shadow: 1px 1px 2px ${(props) => props.darkColor};
  font-size: 18px;
  color: ${(props) => props.textColor};
  text-decoration: underline;
  text-decoration-color: ${(props) => props.lightColor};
  transition: 0.1s;
  &:hover {
    text-shadow: 2px 2px 1px ${(props) => props.darkColor};
    cursor: pointer;
  }
`;

type Link = {
  text: string;
  url: string;
};
const links: Link[] = [
  { text: 'Resume', url: 'https://adin.dev/adin-kwok-resume.pdf' },
  { text: 'GitHub', url: 'https://github.com/adindotdev' },
  { text: 'LinkedIn', url: 'https://www.linkedin.com/in/adinkwok/' },
  { text: 'Twitch', url: 'https://www.twitch.tv/adindotdev' },
];

function Header(props: StateProps) {
  return (
    <Container {...props}>
      <TitleDiv {...props}>
        <h1>Adin Kwok</h1>
      </TitleDiv>
      <LinksDiv>
        {links.map((link) => (
          <a href={link.url} key={link.text} target='_blank' rel='noreferrer'>
            <LinkText {...props} key={link.text}>
              {link.text}
            </LinkText>
          </a>
        ))}
      </LinksDiv>
    </Container>
  );
}

const mapState = (state: RootState) => ({
  ...state.theme.themeSettings,
});
type StateProps = ReturnType<typeof mapState>;

export default connect<StateProps, {}, {}, RootState>(mapState, {})(Header);
