import { configureStore } from '@reduxjs/toolkit';
import themeReducer from './themeReducer';

/**
 * https://react-redux.js.org/tutorials/typescript-quick-start
 */
export const store = configureStore({
  reducer: {
    theme: themeReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
