import { connect } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../state/store';

const Input = styled.div<StateProps>`
  width: 100%;
  box-shadow: 6px 6px 4px ${(props) => props.darkColor};
  background-color: black;
  font-family: 'Roboto Mono', monospace;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  font-size: 16px;
`;

const Env = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Precursor = styled.span`
  color: #b3e5fc;
`;

const Cursor = styled.span`
  background-color: white;
  color: black;
  animation: blinker 1500ms step-start infinite;
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;

type Props = StateProps & { precursor: string; input: string };

function InputTitle(props: Props) {
  return (
    <Input {...props}>
      <Env>
        <Precursor>{props.precursor}</Precursor>
        {props.input}
        <Cursor>&nbsp;</Cursor>
      </Env>
    </Input>
  );
}

const mapState = (state: RootState) => ({
  ...state.theme.themeSettings,
});
type StateProps = ReturnType<typeof mapState>;

export default connect<StateProps, {}, {}, RootState>(mapState, {})(InputTitle);
