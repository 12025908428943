import { connect } from 'react-redux';
import styled from 'styled-components';
import { WIDTH } from '../common/sizes';
import { RootState } from '../state/store';

const WhiteKeyWidth = (1 / 7) * 100;

const BlackKeySpacer1 = styled.div`
  height: 100%;
  width: ${(WhiteKeyWidth * 3) / 4}%;
`;

const BlackKeySpacer2 = styled.div`
  height: 100%;
  width: ${WhiteKeyWidth / 2}%;
`;

const BlackKeySpacer3 = styled.div`
  height: 100%;
  width: ${((WhiteKeyWidth * 3) / 4) * 2}%;
`;

const pianoKeyFiles: string[] = [
  'C',
  'Csharp',
  'D',
  'Dsharp',
  'E',
  'F',
  'Fsharp',
  'G',
  'Gsharp',
  'A',
  'Asharp',
  'B',
];

const PianoDiv = styled.div<StateProps>`
  position: relative;
  min-height: 150px;
  width: ${WIDTH};
  min-width: 340px;
  max-width: 600px;
  box-shadow: 6px 6px 4px ${(props) => props.darkColor};
  margin-bottom: 25px;
`;

const WhiteKeyDiv = styled.div<StateProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  background-color: ${(props) => props.darkColor};
`;

const BlackKeyDiv = styled.div<StateProps>`
  pointer-events: none;
  border-radius: 10px;
  margin-left: 1px;
  position: absolute;
  width: 100%;
  height: 60%;
  display: flex;
`;

const WhiteKey = styled.div<StateProps>`
  height: 100%;
  width: ${WhiteKeyWidth}%;
  background-color: ${(props) => props.lightColor};
  margin-left: 1px;
  transition: 0.1s;
  box-shadow: 0px -6px 4px ${(props) => props.darkColor};
  &:hover {
    box-shadow: 0px -8px 4px ${(props) => props.darkColor};
    background-color: ${(props) => props.silver};
    cursor: pointer;
  }
  &:active {
    box-shadow: 0px -4px 4px ${(props) => props.darkColor};
  }
`;

const BlackKey = styled.div<StateProps>`
  pointer-events: auto;
  border-radius: 10px;
  height: 100%;
  width: ${WhiteKeyWidth / 2}%;
  background-color: ${(props) => props.lightColor};
  transition: 0.1s;
  box-shadow: 4px 4px 4px ${(props) => props.darkColor};
  &:hover {
    box-shadow: 6px 6px 4px ${(props) => props.darkColor};
    background-color: ${(props) => props.silver};
    cursor: pointer;
  }
  &:active {
    box-shadow: 2px 2px 4px ${(props) => props.darkColor};
  }
`;

function Piano(props: StateProps) {
  const pianoKeyAudios: HTMLAudioElement[] = pianoKeyFiles.map((f) => {
    const audio = new Audio(`/sound_fx/${f}.mp3`);
    audio.load();
    return audio;
  });

  const play = (i: number) => {
    pianoKeyAudios[i].load();
    pianoKeyAudios[i].play();
  };

  return (
    <PianoDiv {...props}>
      <WhiteKeyDiv {...props}>
        <WhiteKey {...props} onClick={() => play(0)} />
        <WhiteKey {...props} onClick={() => play(2)} />
        <WhiteKey {...props} onClick={() => play(4)} />
        <WhiteKey {...props} onClick={() => play(5)} />
        <WhiteKey {...props} onClick={() => play(7)} />
        <WhiteKey {...props} onClick={() => play(9)} />
        <WhiteKey {...props} onClick={() => play(11)} />
      </WhiteKeyDiv>
      <BlackKeyDiv {...props}>
        <BlackKeySpacer1 />
        <BlackKey {...props} onClick={() => play(1)} />
        <BlackKeySpacer2 />
        <BlackKey {...props} onClick={() => play(3)} />
        <BlackKeySpacer3 />
        <BlackKey {...props} onClick={() => play(6)} />
        <BlackKeySpacer2 />
        <BlackKey {...props} onClick={() => play(8)} />
        <BlackKeySpacer2 />
        <BlackKey {...props} onClick={() => play(10)} />
      </BlackKeyDiv>
    </PianoDiv>
  );
}

const mapState = (state: RootState) => ({
  ...state.theme.themeSettings,
});
type StateProps = ReturnType<typeof mapState>;

export default connect<StateProps, {}, {}, RootState>(mapState, {})(Piano);
